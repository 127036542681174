import React, {useState, useEffect} from 'react';
import {Button, Container, Card, CardBody, Table, Input, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../../../assets/css/loading.css'
import {getList} from 'helpers/ListHelper';
import AuthHelper from 'helpers/AuthHelper';

const ListRecords = () => {
    const [persons, setPersons] = useState([]);
    const [namesOrRunSearchTerm, setNamesOrRunSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [townSearchTerm, setTownSearchTerm] = useState('');
    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [registeredTowns, setRegisteredTowns] = useState([]);
    const [registeredStates, setRegisteredStates] = useState([]);

    const fetchPersons = async () => {
        const usr = await getList("person/typeuser/"+ AuthHelper.getUserId());
        const professionalType = usr[0].name;

        let endpoint = "person/listpatientbyprofessional/" + AuthHelper.getUserId();

        if (professionalType === "Especialista") {
            endpoint = "person/listpatientbyspecialist/" + AuthHelper.getUserId();
        }

        if(professionalType == "Administrador"){
            endpoint = "person/listPatient";
        }

        const res = await getList(endpoint);
        setLoading(false);
        setPersons(res);
        fetchRegisteredTownsAndStates(res);
    };
    
    const fetchRegisteredTownsAndStates = (persons) => {
        const registeredTowns = []
        const registeredStates = []
            persons.forEach((person) => {
                if (!registeredTowns.includes(person.town_name)) {
                    registeredTowns.push(person.town_name);
                }
                if (!registeredStates.includes(person.estado)) {
                    registeredStates.push(person.estado);
                }
            })
            setRegisteredStates(registeredStates);
            setRegisteredTowns(registeredTowns);
        }

    useEffect(() => {
        fetchPersons();
    }, []);

    return (
        <Container className="mt-3">
            <Card>
                <CardBody>
                    <h1>Fichas Clinicas</h1>
                        <Row>
                                <Col md="6">
                                    <Input type="text" placeholder="Buscar Paciente..." autoComplete="off" name="look_for_namesOrRun" 
                                        value={namesOrRunSearchTerm}
                                        onChange={e => setNamesOrRunSearchTerm(e.target.value)}
                                    />
                                </Col>
                                <Col md= "3">
                                    <Input type="select" autoComplete="off" name="look_for_town" 
                                            value={townSearchTerm}
                                            onChange={e => setTownSearchTerm(e.target.value)}
                                    >  
                                            <option value="Todas las comunas">Todas las comunas</option>
                                            {registeredTowns.map( town => <option value={town}>{town}</option>)} 
                                    </Input>                              
                                </Col>
                                <Col md = "3">
                                    <Input type="select" autoComplete="off" name="look_for_state" 
                                            value={stateSearchTerm}
                                            onChange={e => setStateSearchTerm(e.target.value)}
                                    >
                                            <option value="Todos los estados">Todos los estados</option>            
                                            {registeredStates.map( state => <option value={state}>{state}</option>)}                             
                                    </Input>
                                </Col>
                        </Row>
                    <br/>
                    {loading 
                        ?<div className="w-100 d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                        :<Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr className="text-center">
                                    <th className="px-2" scope="col">Nombre paciente</th>
                                    <th className="px-2"scope="col">Run</th>
                                    <th className="px-2" scope="col">Comuna</th>
                                    <th className="px-2" scope="col">Estado</th>
                                    <th className="px-2" scope="col">Ficha Clinica</th>
                                    <th className="px-2" scope="col"></th>
                                </tr>
                            </thead>
                            {/* {loading && <div className="loader align-self-center text-center justify-content-center"></div>} */}
                            <tbody>
                                {persons.filter(val => {
                                     if(namesOrRunSearchTerm == "" && (townSearchTerm == "" || townSearchTerm == "Todas las comunas") && (stateSearchTerm == "" || stateSearchTerm == "Todos los estados")){
                                        return val;
                                    }else if(namesOrRunSearchTerm == "" && (townSearchTerm == "" || townSearchTerm == "Todas las comunas")){
                                        if(val.estado.toLowerCase().includes(stateSearchTerm.toLowerCase())) return val;
                                    }else if(namesOrRunSearchTerm == "" && (stateSearchTerm == "" || stateSearchTerm == "Todos los estados")){
                                        if(val.town_name.toLowerCase().includes(townSearchTerm.toLowerCase())) return val;
                                    }else if((townSearchTerm == "" || townSearchTerm == "Todas las comunas") && (stateSearchTerm == "" || stateSearchTerm == "Todas los estados")){
                                        if(val.run.includes(namesOrRunSearchTerm) || val.fullname.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase())) return val;
                                    }else if(namesOrRunSearchTerm == ""){
                                        if(val.estado.toLowerCase().includes(stateSearchTerm.toLowerCase()) && val.town_name.toLowerCase().includes(townSearchTerm.toLowerCase())) return val;
                                    }else if((townSearchTerm == "" || townSearchTerm == "Todas las comunas")){
                                        if(val.run.includes(namesOrRunSearchTerm) || val.fullname.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase()) && val.estado.toLowerCase().includes(stateSearchTerm.toLowerCase()) ) return val;
                                    }else if((stateSearchTerm == "" || stateSearchTerm == "Todos los estados")){
                                        if(val.run.includes(namesOrRunSearchTerm) || val.fullname.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase()) && val.town_name.toLowerCase().includes(townSearchTerm.toLowerCase())) return val;
                                    }else if((val.run.includes(namesOrRunSearchTerm) || val.fullname.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase())) && val.town_name.toLowerCase().includes(townSearchTerm.toLowerCase()) && val.estado.toLowerCase().includes(stateSearchTerm.toLowerCase())){
                                        return val;
                                    }       
                                    // if(namesOrRunSearchTerm == "") {
                                    //     return val;
                                    // } else if (val.run.includes(namesOrRunSearchTerm) || val.fullname.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase()) || val.town_name.toLowerCase().includes(namesOrRunSearchTerm.toLowerCase())) {
                                    //     return val;
                                    // }
                                }).map(e_person => (
                                    <tr className="text-center text-uppercase" key={e_person.person_id}>
                                        
                                        <td align="left" className="px-2" style={{whiteSpace:"nowrap", overflow:"hidden", maxWidth:"250px", textOverflow: "ellipsis"}}>
                                            {e_person.names + " " + e_person.lastnames}
                                        </td>
                                        <td className="px-2">
                                            {e_person.run}
                                        </td>
                                        <td className="px-2">
                                            {e_person.town_name}
                                        </td>
                                        <td className="px-2">
                                            {e_person.estado}
                                        </td>
                                        
                                        
                                        <td>
                                            {/* <Button onClick={() => showModalAppointments(e_person)}>Listar</Button> */}
                                            <Link to={{
                                                    pathname: "/ficha-clinica",
                                                    state: {
                                                        id: e_person.person_id,
                                                        run: e_person.run,
                                                        name: e_person.names,
                                                        apellido: e_person.lastnames
                                                    }
                                                }} style={{color: "white"}}><Button color="primary">
                                                Ver</Button></Link>
                                            
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                </CardBody>
            </Card>
        </Container>
    );
};

export default ListRecords;
