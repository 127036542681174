import React, { useState, useEffect } from "react"
import {Button, Col, FormGroup, Input, Label,Modal, ModalHeader, ModalBody} from 'reactstrap'
import Calendar from './calendar'
import Schedule from './schedule'
import moment from 'moment'
import axios from '../../../helpers/axiosConfig';

import { getList } from "helpers/ListHelper";



const ModalAppointment = ({person, professional,setProfessional, professionals, displayModalAppointment,setDisplayModalAppointment, appointment, setAppointment, fetchAppointments, update}) => {
    const today = moment().format('YYYY-MM-DD')
    const [selectedDay, setSelectedDay] = useState(today)

    

    //const [newEvent, setNewEvent] = useState({})
    
    
    // const defaultDateAppointment = {start: "09:00", end: "11:00"}
    // const [dateAppointment, setDateAppointment] = useState(defaultDateAppointment)


    const defaultAppointment = { cons_appo_id: 0, cons_appo_date: moment().format('YYYY-MM-DD'), cons_appo_start: "09:00", cons_appo_end: "11:00", professional_id: "", cons_appo_place: "", cons_appo_description: "" };
    //const [appointment, setAppointment] = useState(defaultAppointment)

    

    const defaultRangeAgenda = {minTime: "07:00",maxTime: "21:00"}
    const [rangeAgenda, setRangeAgenda] = useState(defaultRangeAgenda)

    // const defaultProfessional = { professional_id: 0, professional_fullname: "" };
    // const [professional, setProfessional] = useState(defaultProfessional);

    const [calendarEvents, setCalendarEvents] = useState([])


    const getData = async (set, route) => {
        const e = await getList(route);
        set(e);
    }

    const handleInputChangeProfessional = e => {
        console.log(e.target)
        setProfessional({
            ...professional,
            [e.target.name]: e.target.value
        });

        setAppointment({
            ...appointment,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        getData(setCalendarEvents, `/agenda/${professional.professional_id}`)
    }, [professional,displayModalAppointment])


    const cleanModal=()=>{
        setAppointment(defaultAppointment)
        setCalendarEvents([])
        setProfessional(0);
    }



    const handleInputChange = e => {
        setAppointment({
            ...appointment,
            [e.target.name]: e.target.value
        })
    }

    const setStatusPerson = async (e) => {
        const res = await axios.put('person/set-status-person/',
        {
            status_id: 2,
            personId: e
        }
        )

        if (!res.data.error) {
            console.log('Status actualizado');
        };

    };

    const saveAppointment = async () => {
        try {
            const res = await axios.post("consultation-appointment",
                {
                    person_id: person.person_id,
                    cons_appo_date: appointment.cons_appo_date,
                    cons_appo_start: appointment.cons_appo_start,
                    cons_appo_end: appointment.cons_appo_end,
                    professional_id: professional.professional_id,
                    cons_appo_place: appointment.cons_appo_place,
                    cons_appo_description: appointment.cons_appo_description
                });
            if (!res.data.error) {
                setStatusPerson(person.person_id);
                setDisplayModalAppointment(!displayModalAppointment);
                
                //setNewAppointment(true);
                //showModalAppointments(person);
            } else {
                console.log('Err: ' + res.data.result.code + ' ' + res.data.result.sqlMessage);
            };
            cleanModal()
        } catch (e) {
            console.log('Err: ' + e);
        };
    };


    
    const updateAppointment = async () => {
        try {
            const res = await axios.post("consultation-appointment/" + appointment.cons_appo_id,
                {
                    cons_appo_date: moment(appointment.cons_appo_date).format('YYYY-MM-DD'),
                    cons_appo_start: appointment.cons_appo_start,
                    cons_appo_end: appointment.cons_appo_end,
                    professional_id: professional.professional_id,
                    cons_appo_place: appointment.cons_appo_place,
                    cons_appo_description: appointment.cons_appo_description
                });

            if (!res.data.error) {
                setDisplayModalAppointment(!displayModalAppointment);
                fetchAppointments(person.person_id);
            } else {
                console.log('Err: ' + res.data.result.code + ' ' + res.data.result.sqlMessage);
            };
            cleanModal()
        } catch (e) {
            console.log('Err: ' + e);
        };
    };


    return (
        <Modal className="overflow-auto centered" size="lg" isOpen={displayModalAppointment}>
            <ModalHeader className="pb-2" toggle={() => { setDisplayModalAppointment(!displayModalAppointment) }}>
                <div className="row">
                    
                        <Label size="sm" sm={12} className="form-control-label">Paciente: <span style={{ color: "#DC3545" }}>{person?.names + " " + person?.lastnames}</span> - Sector de residencia: <span style={{ color: "#DC3545" }}>{person?.town_name}</span> </Label>
                    
                </div>                               
            </ModalHeader>
            <ModalBody className="p-2">
                <FormGroup className="row m-0">
                    <Col className="col-6">
                        <FormGroup className="row m-0">
                            <Col sm={12}>
                                <Label className="form-control-label">Profesional</Label>
                                <Input
                                    bsSize="sm"
                                    id="select"
                                    name="professional_id"
                                    value={appointment.professional_id}
                                    type="select"
                                    onChange={e => handleInputChangeProfessional(e)}>
                                    <option hidden value="">Seleccione profesional...</option>
                                    {professionals.map((professional) =>
                                            <option 
                                                key={professional.professional_id+ '' + professional.professional_medical_area_id} 
                                                value={professional.professional_id}
                                            >
                                                {professional.professional_fullname}
                                            </option>
                                    )
                                    }
                                </Input>
                            </Col>
                            {/* CALENDARIO */}
                            <Col className="col-12 pt-3">
                                <Calendar setSelectedDay={setSelectedDay}/>
                            </Col>
                            <Col className="col-12">
                                <FormGroup className="row">
                                    <Col className="col-12 pt-3">
                                        <Label className="form-control-label">Lugar</Label>
                                        <Input
                                            bsSize="sm"
                                            autoComplete="off" maxLength="250" type="text"
                                            name="cons_appo_place"
                                            value={appointment.cons_appo_place}
                                            onChange={e => handleInputChange(e)}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col className="col-12">
                                        <Label className="form-control-label">Descripción</Label>
                                        <Input
                                            bsSize="sm"
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="cons_appo_description"
                                            value={appointment.cons_appo_description}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    {/* HORARIO */}
                    <Col className="col-6">
                        <Schedule 
                            update={update}
                            selectedDay={selectedDay} 
                            rangeAgenda={rangeAgenda} 
                            calendarEvents={calendarEvents}
                            appointment={appointment} 
                            setAppointment={setAppointment}
                            professional={professional.professional_id}
                            setCalendarEvents={setCalendarEvents}
                        />
                    </Col>
                </FormGroup>
                <div className="float-right mb-2 mr-2">
                    {appointment.cons_appo_id === 0
                        ? <Button color="primary" onClick={() => { saveAppointment() }}>Guardar</Button>
                        : <Button color="primary" onClick={() => { updateAppointment() }}>Modificar</Button>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalAppointment