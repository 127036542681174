import React from "react";
import teeth from "./teeth-svg.json"
import Tooth from "./Tooth";

function Teeth({teethStatus, setTeethStatus, setTooth}) {


    const handleTeethStatus=(e)=>{ 
        console.log("target",e.currentTarget)
        setTooth(e.currentTarget.id)
        const newTeethStatus = teethStatus.map((tooth, index) => 
            tooth.fdi_name === e.currentTarget.id ? {...tooth, tooth_state_id: (tooth.tooth_state_id ===5 ? 1  : tooth.tooth_state_id+1) }: tooth
        )
        setTeethStatus(newTeethStatus)
    }

    console.log(teethStatus)

    return (
        <>
            {
                teeth.map( (tooth, index) =>
                    <Tooth key={tooth.id} status={teethStatus[index]?.tooth_state_id} handleTeethStatus={handleTeethStatus} id={tooth.id} paths={tooth.path}/>
                )
            }       
        </>
    );
}

export default Teeth;
