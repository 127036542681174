import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import * as THREE from 'three'

export default function Model(props) {

    const {teeth, scale, position, opacity} = props
    const {nodes, materials } = useGLTF('/mesh/fantoma.gltf')
    const group = useRef()

    return (
        <group ref={group} scale={scale} position={position}>
            <mesh
                material={materials.encia}
                geometry={nodes.reb_alv_inf_papilas.geometry}
                position={[-0.000799172383267432, -0.009652681648731232, 0.03410725295543671]}
                rotation={[1.8928228644193699, 0, 0]}
                scale={[0.2582273781299591, 0.2582273781299591, 0.2582273781299591]}
                material-opacity={opacity}
                material-transparent={true}
                material-side={THREE.FrontSide}
            />
            <mesh
                material={materials.encia}
                geometry={nodes.reb_alv_sup_papilas.geometry}
                position={[0.000345383945386857, 0.025681160390377045, 0.050578609108924866]}
                rotation={[1.7410711657057512, 0, 0]}
                scale={[0.27773573994636536, 0.35650089383125305, 0.2803818881511688]}
            /><>
            {typeof props.teeth !== 'undefined' &&
                teeth.map((tooth) =>
                    tooth.tooth_state_id === 1 ? <></> :
                        tooth.tooth_state_id === 2 ? <mesh
                            key={`${tooth.fdi_name.replace(/\./g, '')}`}
                            material={materials.esmalte}
                            geometry={nodes[`${tooth.fdi_name.replace(/\./g, '')}`].geometry}
                            position={nodes[`${tooth.fdi_name.replace(/\./g, '')}`].position}
                            rotation={nodes[`${tooth.fdi_name.replace(/\./g, '')}`].rotation}
                            scale={nodes[`${tooth.fdi_name.replace(/\./g, '')}`].scale}
                        /> : tooth.tooth_state_id === 3 ?
                            <mesh
                                key={`${tooth.fdi_name.replace(/\./g, '')}DF`}
                                material={materials.esmalte}
                                geometry={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].geometry}
                                position={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].position}
                                rotation={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].rotation}
                                scale={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].scale}
                            /> : tooth.tooth_state_id === 4 ?
                                <mesh
                                    key={`${tooth.fdi_name.replace(/\./g, '')}RR`}
                                    material={materials.esmalte}
                                    geometry={nodes[`${tooth.fdi_name.replace(/\./g, '')}RR`].geometry}
                                    position={nodes[`${tooth.fdi_name.replace(/\./g, '')}RR`].position}
                                    rotation={nodes[`${tooth.fdi_name.replace(/\./g, '')}RR`].rotation}
                                    scale={nodes[`${tooth.fdi_name.replace(/\./g, '')}RR`].scale}
                                /> : tooth.tooth_state_id === 5 ?
                                    <mesh
                                        key={`${tooth.fdi_name.replace(/\./g, '')}I`}
                                        material={materials.esmalte}
                                        /* geometry={nodes[Object.values(props.teeth)[i].fdi_name+'RR'].geometry} */
                                        position={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].position}
                                        rotation={nodes[`${tooth.fdi_name.replace(/\./g, '')}DF`].rotation}
                                        scale={[0.1, 0.1, 0.1]}
                                    >
                                        <cylinderBufferGeometry attach="geometry" args={[0.015, 0.015, 0.08, 32]} />
                                        <meshStandardMaterial color={'#878681'} />
                                    </mesh> : <></>
                            
                )


                
            }</>
        </group>
    )

}

useGLTF.preload('/mesh/fantoma.gltf')
