import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Col
} from "reactstrap";
import Legend from "views/pages/anamnesis/legend-svg";
import Teeth from "./Teeth";


const Odontogram = ({ teethStatus, setTeethStatus, ...props }) => {
    const [tooth, setTooth] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const saveData = () => {
        setTooth(null);
        props.updateOdontogram(props.odontogramId, teethStatus);
        props.setShowModal(false);
        props.getTeethState(props.odontogramId);
        props.getOdontogramId();
        toggle()
        console.log("teethStatus: ",teethStatus)
    };

    return (
        <Modal
            size="lg"
            centered
            isOpen={props.showModal}
            // cssModule={{ "modal-title": "w-100 text-center" }}
            // style={{ maxWidth: "90%", maxHeight: "100%" }}
            scrollable
        >
            <ModalHeader>Selector de piezas dentales</ModalHeader>
            <ModalBody className="p-0">
                <div className="row m-0">
                    <Col>
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="svg8"
                                width="100%"
                                height="500"
                                version="1.1"
                                viewBox="0 0 125.857 200.777"
                            >
                                <Teeth
                                    teethStatus={teethStatus}
                                    setTeethStatus={setTeethStatus}
                                    setTooth={setTooth}
                                />
                                <Legend/>
                        </svg> 
                    </Col>
                
                </div>
            </ModalBody> 
            <ModalFooter className="p-1">
                <Button color="primary" onClick={() => saveData()}>
                    Guardar
                </Button>
                <Button color="secondary" onClick={() => props.setShowModal(false)}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Odontogram;

