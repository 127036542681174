import React, { useState, useEffect, useRef } from 'react'
import { Html } from "@react-three/drei";
import { useThree } from "react-three-fiber";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    CardFooter,
    Row,
    Col,
    CardImgOverlay,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";


const TagInfo = ({props, active, setActive, form, color, surface, consistency, evolutionForm, symptomatology, edge, injury, localization, src, alt }) => {
    const htmlref = useRef()
    
    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = () => setModalDelete(!modalDelete);

    const [modalImage, setModalImage] = useState(false);
    const toggleImage = () => setModalImage(!modalImage);

    const styleTd = {
        whiteSpace: 'normal',
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        textAlign: 'left',
        textJustify: 'inter-word',
        maxWidth: '50%',
    }
    const styleTh = {
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        textAlign: 'left',
        textJustify: 'inter-word',
        maxWidth: '50%',
    }

    return (
        <Html ref={htmlref} className='html-drei' style={{ transform: 'translate3d(15%,-50%, 0)' }} zIndexRange={[90, 0]} >
            <Card style={{ display: active ? 'block' : 'none', width: "21rem", wordWrap: 'inherit' }}>
                <Button onClick={() => { setActive(!active) }} className="close" color="primary" size="lg" style={{ zIndex: 100, position: 'absolute', right: "0px", top: "0.4rem" }}><span aria-hidden="true">&times;</span></Button>
                <ButtonGroup size="lg" style={{ zIndex: 100, position: 'absolute', left: "0px", top: "0px" }}>
                    <Button color="primary" size="lg" onClick={() => { props.editMarker(props.marker) }}><i className="fas fa-pencil-alt"></i></Button>
                    <Button color="danger" size="sm" onClick={toggleDelete}><i className="far fa-trash-alt"></i></Button>
                </ButtonGroup>
                <CardImgOverlay onClick={()=>toggleImage()}></CardImgOverlay>

                       <Modal centered={true} size="xl" isOpen={modalImage} toggle={toggleImage}>
                        <ModalHeader toggle={toggleImage} ></ModalHeader>
                        <ModalBody>
                            <img
                            src={src}
                            className="img-fluid"
                            alt={alt}
                        /></ModalBody> 
                        </Modal>

                <CardImg
                    alt={alt}
                    src={src}
                    top
                />
                <CardBody style={{ padding: "5px" }}>
                    <Table className="table table-sm" >
                        <tbody>
                            <tr>
                                <th style={styleTh} scope="row">Sospecha:</th>
                                <td style={styleTd}>{injury}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Forma:</th>
                                <td style={styleTd}>{form}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Tamaño:</th>
                                <td style={styleTd}>{props.marker.length} x {props.marker.width} x {props.marker.height} mm</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Color:</th>
                                <td style={styleTd}>{color}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Superficie:</th>
                                <td style={styleTd}>{surface}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Consistencia:</th>
                                <td style={styleTd}>{consistency}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Tiempo de evolución:</th>
                                <td style={styleTd}>{props.marker.evolution_time}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Forma de evolución:</th>
                                <td style={styleTd}>{evolutionForm}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Sintomatología:</th>
                                <td style={styleTd}>{symptomatology}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Bordes:</th>
                                <td style={styleTd}>{edge}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Localización:</th>
                                <td style={styleTd}>{localization}</td>
                            </tr>
                            <tr>
                                <th style={styleTh} scope="row">Observaciones:</th>
                                <td style={styleTd}>{props.marker.observation}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <Modal isOpen={modalDelete} toggle={toggleDelete}>
                <ModalHeader toggle={toggleDelete} >Eliminar</ModalHeader>
                <ModalBody>¿Seguro que desea eliminar el marcador?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { props.deleteMarker(props.marker.id) }}>Confirmar</Button>{' '}
                    <Button color="secundary" onClick={toggleDelete}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </Html>
    )

}
export default TagInfo