import React, {useState, useRef, useEffect} from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es"; 
import moment from "moment";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "assets/css/app.css"

const Schedule = ({selectedDay, rangeAgenda, setCalendarEvents, calendarEvents, appointment, setAppointment, professional, update }) => {

    const scheduleRef = useRef()

    const gotoSelectedDay = (selectedDay) => {
        let scheduleApi = scheduleRef.current.getApi()
        scheduleApi.gotoDate(selectedDay)
    }

    const [newEvent, setNewEvent] = useState({})
    //const [editableEvent, setEditableEvent] = useState({})

    

    const [listEvents, setListEvents] = useState([])



    const handleDateSelection = arg => {
        console.log(arg.start)
        const calendarApi = scheduleRef.current.getApi();
        calendarApi.unselect() 
        setNewEvent({
                id:"new-event",
                title: update?'Cita actualizada':'Nueva cita',
                start:arg.start,
                end:arg.end,
                editable:true,
                className : update?'bg-warning':'bg-info',
                constraint:"businessHours",
            }
        )
        setAppointment(
            {
                ...appointment, 
                cons_appo_date:moment.utc(arg.start ,"YYYY-MM-DD").format("YYYY-MM-DD") ,
                cons_appo_start: moment.utc(arg.start, "HH:mm").format('HH:mm'),
                cons_appo_end: moment.utc(arg.end, "HH:mm").format('HH:mm'),
            }
        )
    }

    const handleEventEdit = ({event}) =>{
        update? setNewEvent({...newEvent, title: event.title , start:event.start, end:event.end, className : 'bg-warning', }):
        setNewEvent({...newEvent, title: event.title , start:event.start, end:event.end})
    }

    


    useEffect(() => {
        setListEvents([...calendarEvents,newEvent])
    }, [calendarEvents,newEvent])


    useEffect(() => {
        gotoSelectedDay(selectedDay)
    }, [selectedDay])




    const handleEventUpdate = ({event}) =>{
        const calendarApi = scheduleRef.current.getApi();
        calendarApi.unselect() 
        const totalEventos =calendarEvents.filter((evento => evento.id!==appointment.cons_appo_id))
        const editableEvent = calendarEvents.filter((evento => evento.id===appointment.cons_appo_id))
        //setNewEvent({...newEvent, className : 'bg-warning' })
        setCalendarEvents(totalEventos)
        handleEventEdit({event})
    }

    console.log(calendarEvents)

    return (
        <FullCalendar
            ref={scheduleRef}
            defaultView="timeGridDay"
            titleFormat={{ month: "long", day: 'numeric'}}
            header={{
                left: "prev today",
                center: "title",
                right: "next"
            }}
            locale={esLocale}
            aspectRatio= "2"
            selectConstraint={"businessHours"}
            validRange= {(nowDate) =>{
                return {start: nowDate}
            }}
            minTime={rangeAgenda.minTime}
            maxTime={rangeAgenda.maxTime}
            height= "auto"
            businessHours={{daysOfWeek:[1,2,3,4,5], startTime: "09:00", endTime: "19:00"}}
            fixedWeekCount={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            nowIndicator={true}
            timeZone="GMT-4"
            allDaySlot={false}
            defaultTimedEventDuration="02:00"
            events={listEvents}
            select={handleDateSelection}
            selectable={true}
            editable={false}
            eventOverlap={false}
            selectOverlap={false}
            slotEventOverlap={false}
            eventDrop={handleEventUpdate}
            eventResize={handleEventUpdate}
            slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
            }}
            eventClassName={"bg-primary"}
            />
    )
}

export default Schedule