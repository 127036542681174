import React from 'react'
import { Button, Table } from 'reactstrap'
import { TableExport } from 'tableexport'

const SummaryTable = (props) => {

  const { odontogramStatus, patient } = props

  const downloadOdontogram = () => {

    const table = document.querySelector("#summarytable");
    const tableExport = new TableExport(table, {
      exportButtons: false,
      filename: `${patient.run}_${patient.name}- Resumen odontograma`,
      sheetname: "Resumen",
    });

    const data = tableExport.getExportData();
    
    const documentPreferences = data.summarytable.xlsx;

    tableExport.export2file(
      documentPreferences.data,
      documentPreferences.mimeType,
      documentPreferences.filename,
      documentPreferences.fileExtension,
      documentPreferences.merges,
      documentPreferences.RTL,
      documentPreferences.sheetname
    );
  }

  return (
    <>
      <Table id="summarytable" size="sm" bordered responsive >
        <thead>
          <tr>
            <th className="p-2 font-weight-bold text-center" colSpan="2">Estado</th>
            <th className="p-2 font-weight-bold text-center">Dientes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" colSpan="2">Sano</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.healthy === 1 )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
            </td>
          </tr>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" rowSpan="4">Caries</td>
            <td className="p-2 align-middle">Lesión de caries Incipiente</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.coronal_cavitie !== null & item.coronal_cavitie !== undefined)
              .map(item => item)
              .filter(item => JSON.parse(item.coronal_cavitie).classification === 'ICDAS 1' || JSON.parse(item.coronal_cavitie).classification === 'ICDAS 2'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
            </td>
          </tr>
        <tr>
            <td className="p-2 align-middle">Lesión de caries Moderada</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.coronal_cavitie !== null & item.coronal_cavitie !== undefined)
              .map(item => item).filter(item => JSON.parse(item.coronal_cavitie).classification === 'ICDAS 3' || JSON.parse(item.coronal_cavitie).classification === 'ICDAS 4'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
              </td>
          </tr>
          <tr>
            <td className="p-2 align-middle">Lesión de caries Extensa</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.coronal_cavitie !== null & item.coronal_cavitie !== undefined)
              .map(item => item)
              .filter(item => JSON.parse(item.coronal_cavitie).classification === 'ICDAS 5' || JSON.parse(item.coronal_cavitie).classification === 'ICDAS 6'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
              </td>
          </tr>
          <tr>
            <td className="p-2 align-middle">Caries Radicular</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.radicular_cavitie !== null & item.radicular_cavitie !== undefined)
              .map(item => item)
              .filter(item => JSON.parse(item.radicular_cavitie).classification === 'ICDAS 1' || JSON.parse(item.radicular_cavitie).classification === 'ICDAS 2'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
              </td>
          </tr>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" rowSpan="2">Restauración</td>
            <td className="p-2 align-middle">Restauración satisfactoria</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.restoration !== null & item.restoration !== undefined).map(item => item)
              .filter(item => JSON.parse(item.restoration).status === 'Alfa' || JSON.parse(item.restoration).status === 'Beta'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
            </td>
          </tr>
          <tr>
            <td className="p-2 align-middle">Restauración defectuosa</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.restoration !== null & item.restoration !== undefined)
              .map(item => item)
              .filter(item => JSON.parse(item.restoration).status === 'Charlie' || JSON.parse(item.restoration).status === 'Delta'  )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
              </td>
          </tr>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" colSpan="2">Lesión no cariosa</td>
            <td className="p-2 text-center text-wrap">
              {odontogramStatus
              .filter(item => item.non_carious_injury !== null & item.non_carious_injury !== undefined)
              .map(item => item)
              .filter(item => JSON.parse(item.non_carious_injury).type === 'Atrición' || JSON.parse(item.non_carious_injury).type === 'Abfracción' || JSON.parse(item.non_carious_injury).type === 'Erosión' )
              .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
            </td>
          </tr>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" colSpan="2">Prótesis fija/incrustación/ Carilla</td>
            <td className="p-2 text-center text-wrap">
            {odontogramStatus
            .filter(item => item.prosthesis === 1 )
            .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
            </td>
          </tr>
          <tr>
            <td className="p-2 font-weight-bold text-center align-middle" colSpan="2">Diente Endodónticamente tratado</td>
            <td className="p-2 text-center text-wrap">
            {odontogramStatus
            .filter(item => item.endodontically_treated === 1 )
            .map((item, index, array) => index+1===array.length ? item.fdi_name : item.fdi_name+"; " )}
              </td>
          </tr>
        </tbody>
      </Table>
      <div className='py-3'>
        <Button onClick={() => downloadOdontogram()} color="primary">Descargar Odontograma <i className="fa fa-download"></i></Button>
      </div>
    </>
  )
}

export default SummaryTable