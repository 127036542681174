import LoginExp from "views/pages/login/LoginExp";

const routes = [
  {
    path: "/login",
    component: LoginExp,
    layout: "/login"
  }

];

export default routes;