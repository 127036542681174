import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
//import moment from "moment";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "assets/css/app.css"



const Calendar = ({setSelectedDay}) => {

    const calendarRef = useRef()

    

    return (
        <FullCalendar
            ref={calendarRef}
            //defaultView="timeGridMonth"
            titleFormat={{ year: "numeric", month: "long" }}
            header={{
                left: "prev",
                center: "title",
                right: "next"
            }}
            validRange= {function(nowDate){
                return {start: nowDate}
            }}
            
            locale={esLocale}
            aspectRatio="2"
            height="auto"
            fixedWeekCount={true}
            //contentHeight="auto"
            //stickyHeaderDates={true}
            plugins={[dayGridPlugin, interactionPlugin]}
            timeZone="GMT-4"
            showNonCurrentDates={false}
            dateClick={function (info) {
                setSelectedDay(info.dateStr)
            }}
            //editable={true}
            slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
            }}
            eventClassName={"bg-info"}
            eventLimit={true}
            views={{
                    dayGridMonth: {
                        eventLimit: 1,
                        titleFormat: { year: "numeric", month: "long" }
                    }
                }   
            }
        />
    )
}

export default Calendar