import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import { getList } from "helpers/ListHelper";
import moment from "moment";
import { Modal, ModalBody, ModalHeader, Card, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "assets/css/app.css"
// import AgendaConfiguration from "./conf-agenda";
import Axios from '../../../../helpers/axiosConfig'
import ConfigurationSchedule from "./configuration-schedule/conf-schedule";

const Agenda = (props) => {
    const { user, appointmentId, setDate, dashboard, selectable, fixedWeekCount } = props


    const calendarComponentRef = useRef()
    const [calendarEvents, setCalendarEvents] = useState([])

    const [displayModalAppointment, setDisplayModalAppointment] = useState(false);




    ///PREFERENCIAS DE USUARIO
    const defaultTimeRange = { init_hour: "07:00", end_hour: "21:00" }
    const [weekTimeRange, setWeekTimeRange] = useState(defaultTimeRange)

    const [isOpen, setIsOpen] = useState(true)
	const toggle =()=> setIsOpen(!isOpen)


    //eslint-disable-next-line
    const [userPreferences, setUserPreferences] = useState()
    //eslint-disable-next-line
    const [selectedDays, setSelectedDays] = useState([])
    //eslint-disable-next-line
    const [businessHours, setBusinessHours] = useState([])


    ///DATOS DE UNA CITA
    const [appointmentData, setAppointmentData] = useState({
        start: "",
        end: "",
        description: "",
        place: "",
        run: "",
        title: "",
        person_id: "",
        names: "",
        lastnames: ""
    });

    /*VER INFORMACIÓN DE EVENTO*/
    const handleEventClick = (info) => {
        setAppointmentData({
            start: info.event.start,
            end: info.event.end,
            description: info.event.extendedProps.description,
            place: info.event.extendedProps.place,
            run: info.event.extendedProps.run,
            title: info.event.title,
            person_id: info.event.extendedProps.person_id,
            names: info.event.extendedProps.names,
            lastnames: info.event.extendedProps.lastnames
        });
        console.log(info.event)
        setDisplayModalAppointment(!displayModalAppointment);
    };


    const getData = async (set, route) => {
        const e = await getList(route);
        set(e);
    };

    const getCalendarPreferences = async () => {
        const res = await Axios.get("agenda/user_preferences/" + Number(user));
        try {
            setWeekTimeRange({ init_hour: res.data.result[0].init_hour.slice(0,5), end_hour: res.data.result[0].end_hour.slice(0,5) })
        } catch (error) {
            //createCalendarPreferences()
        }

    }

    // const createCalendarPreferences = async () => {
    //     //eslint-disable-next-line
    //     const res = await Axios.post("agenda/user_preferences",
    //         {
    //             user_id: user,
    //             init_hour: weekTimeRange.init_hour,
    //             end_hour: weekTimeRange.end_hour,
    //             business_hours: JSON.stringify({
    //                 daysOfWeek: selectedDays,
    //                 startTime: '08:00',
    //                 endTime: '18:00'
    //             })
    //         }
    //     )
    // }

    const updateCalendarPreferences = async () => {
        try {
            //eslint-disable-next-line
            const res = await Axios.put("agenda/user_preferences/update",
                {
                    init_hour: weekTimeRange.init_hour,
                    end_hour: weekTimeRange.end_hour,
                    business_hours: JSON.stringify({
                        daysOfWeek: selectedDays,
                        startTime: '06:00',
                        endTime: '10:00'
                    }),
                    user_id: user
                }
            )
        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        getData(setCalendarEvents, `/agenda/${user}`)
        getCalendarPreferences()
        //eslint-disable-next-line
    }, [user])



    return (
        <>
            <Modal size="md" isOpen={displayModalAppointment} backdrop={true} centered fade={false}>             
                <ModalHeader toggle={() => { setDisplayModalAppointment(!displayModalAppointment) }}>
                    Consulta
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Card style={{ boxShadow: '0px 0px 0px' }} >
                        <Table className="table table-sm" >
                            <tbody>
                                <tr>
                                    <th scope="row">Paciente:</th>
                                    <td>{appointmentData.title}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Run:</th>
                                    <td>{appointmentData.run}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Fecha:</th>
                                    <td>{moment.utc(appointmentData.start, "DD-MM-YYYY").format("DD-MM-YYYY")}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Hora:</th>
                                    <td>{moment.utc(appointmentData.start, "HH:mm").format("HH:mm")}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Lugar: </th>
                                    <td> {appointmentData.place}</td>
                                </tr>
                                <tr>
                                    <th scope="row"> Descripción:</th>
                                    <td>{appointmentData.description}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                        <div className="text-center">
                         <Link to={{
                             pathname: "/ficha-clinica",
                             state: {
                                 id: appointmentData.person_id,
                                 run: appointmentData.run,
                                 name: appointmentData.names,
                                 apellido: appointmentData.lastnames
                                }
                            }} style={{color: "white"}}><Button color="primary" size="lr"> <i className="fas fa-link"></i> Ver Ficha Clinica </Button>

                         </Link>
                            </div>
                </ModalBody>
            </Modal>

            {/* <ConfigurationSchedule 
                isOpen={isOpen}
                toggle={toggle}
                weekTimeRange={weekTimeRange}
                setWeekTimeRange={setWeekTimeRange}
                updateCalendarPreferences={updateCalendarPreferences}
            /> */}

            <FullCalendar
                ref={calendarComponentRef}
                defaultView="timeGridWeek"
                titleFormat={{ year: "numeric", month: "long", day: 'numeric' }}
                header={{
                    left: "prev,next today",
                    center: "title",
                    right: dashboard ? "dayGridMonth,timeGridWeek,timeGridDay configButton" : "timeGridWeek,timeGridDay configButton"
                }}
                locale={esLocale}
                aspectRatio="2"
                height='auto'
                stickyHeaderDates={true}
                plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                minTime={weekTimeRange.init_hour}
                maxTime={weekTimeRange.end_hour}
                businessHours={businessHours}
                nowIndicator={true}
                timeZone="GMT-4"
                allDaySlot={false}
                defaultTimedEventDuration="02:00"
                events={calendarEvents}
                eventClick={dashboard ? handleEventClick : false}
                selectable={selectable}
                editable={false}
                eventOverlap={false}
                fixedWeekCount={fixedWeekCount}
                slotLabelFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    omitZeroMinute: false,
                }}
                eventClassName={"bg-info"}
                eventLimit={true}
                views={{
                    dayGridMonth: {
                        eventLimit: 1,
                        titleFormat: { year: "numeric", month: "long" }
                    }
                }}
                // customButtons={dashboard && {
                //     configButton: {
                //         text: 'Configuración',
                //         click: toggle
                //     }
                // }}
            />
        </>
    );

}
export default Agenda;


    // <AgendaConfiguration
    //     displayModalConfig={displayModalConfig}
    //     setDisplayModalConfig={setDisplayModalConfig}
    //     user={user}
    //     setSelectedDays={setSelectedDays}
    //     createCalendarPreferences={createCalendarPreferences}
    //     updateCalendarPreferences={updateCalendarPreferences}
    //     getCalendarPreferences={getCalendarPreferences}
    //     setBusinessHours={setBusinessHours}
    //     businessHours={businessHours}
    //     setRangeAgenda={setRangeAgenda}
    //     rangeAgenda={rangeAgenda}
    // /> 
