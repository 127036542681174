import React, { Fragment, useState } from 'react'

const Tooth = (props) => {

    const { id, paths, status, toothSelected, handleClickTooth, colorLegend, selectedColor } = props

    const [hover, setHover] = useState(false)

    const handleOverTooth = (e) => {
        status!==1 && setHover(true)
    }
    const handleLeaveTooth = (e) => {
        status!==1 && setHover(false)
    }

    return (
        <g
            id={id}
            fill={id === toothSelected ? selectedColor : colorLegend[status]}
            stroke={status!==1 ?"#6a6565" : "none"}
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.25"
            display="inline"
            transform="translate(-35 -25)"
            onClick={status!==1 ? handleClickTooth: undefined}
            onMouseOver={handleOverTooth}
            onMouseLeave={ handleLeaveTooth}
            style={{ cursor: status!==1 &&"pointer" }}
        >
            <defs>
                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur
                        result="blurred"
                        in="SourceGraphic"
                        stdDeviation={0.5}
                    />
                </filter>
            </defs>
            {paths.map((path, index) =>
                <Fragment key={`path-${index}`}>
                    {index === 0 && status!==1 &&
                        <use xlinkHref={`#${id + index}`} stroke={ hover ? "#5e72e4" : "#6a6565"} strokeWidth={hover ? "3" : "0.5"} filter="url(#glow)"
                        />}
                    <path
                        key={id + index}
                        id={id + index}
                        strokeLinecap="butt"
                        d={path.d}
                        onMouseOver={handleOverTooth}
                        onMouseLeave={handleLeaveTooth}
                    //onClick={handleClickTooth}
                    //fill={colorLegend[2]}
                    // fill={isSelected? selectedColor: colorLegend[2]}
                    >
                    </path>
                </Fragment>
            )
            }
        </g>
    )
}

export default Tooth