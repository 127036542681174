import React, { useState } from 'react'
import {
    Button,
    FormGroup,
    Form,
    Input,
    Modal,
    Label,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardImg,
    CardTitle,
    CardColumns
} from "reactstrap";


const AddMarkerForm = (props) => {

    const initialFormState = {
        id: null,
        idAnamnesis: null,
        inspection: '',
        palpation: '',
        position_x: 0,
        position_y: 0,
        position_z: 0,
        foto: null
    }
    const [marker, setMarker] = useState(initialFormState)
    const [modalImage, setModalImage] = useState(false);
    const toggleImage = () => setModalImage(!modalImage);

    const handleInputChange = event => {
        const { name, value } = event.target

        setMarker({ ...marker, [name]: value })
    }

    const handleImageModal = () => {
        toggleImage()
    }

    const handleImageTag = (e) => {
        toggleImage()
        marker.foto = e.target.id
        console.log(e.target.id)
    }


    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={props.adding}>
                <ModalHeader>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={event => {
                        event.preventDefault()
                        props.addMarker(marker)
                        setMarker(initialFormState)
                    }}
                        role="form">
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label" >Inspección</Label>
                                <Input
                                    id="example-text-input1"
                                    type="text"
                                    name="inspection"
                                    defaultValue={marker.inspection}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Palpación</Label>
                                <Input
                                    id="example-text-input1"
                                    type="text"
                                    name="palpation"
                                    defaultValue={marker.palpation}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Seleccionar Imagen</Label>

                            </Col>
                            <Col>
                                <Button color="primary"
                                    onClick={() => handleImageModal()}><i className="fas fa-camera"></i>
                                </Button>
                            </Col>
                        </FormGroup>
                        <ModalFooter>
                            <Button type="submit" color="primary" >
                                Guardar
                            </Button>
                            <Button color="secondary"
                                onClick={() => props.setAdding(false)}>Cancelar
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal size='lg' centered={true} isOpen={modalImage} toggle={toggleImage}>
                <ModalHeader toggle={toggleImage} >Imagenes</ModalHeader>
                <ModalBody>
                    <CardColumns>
                        {props.image.map((image) => (
                            <Card key={image.foto_id} >
                                {/* <CardImg onClick={(e) => handleImageTag(e)} id={image.foto_id} top width="50%" height="200" src={process.env.REACT_APP_API_URL + `${image.url}` + "/" + `${image.foto_nombre}`} alt={image.foto_nombre} /> */}
                                <CardImg onClick={(e) => handleImageTag(e)} id={image.foto_id} top width="50%" height="200" src={`${process.env.REACT_APP_API_URL}${image.url}/${image.foto_nombre}`} alt={image.foto_nombre} /> 
                                
                                {/* <Input
                                            id={image.foto_id}
                                            type="image"
                                            name="foto"
                                            defaultValue={marker.foto}
                                            onChange={handleInputChange}
                                        /> */}
                                <CardTitle tag="h5">{image.foto_nombre}</CardTitle>
                            </Card>
                        ))}
                    </CardColumns>
                </ModalBody>
                {/* <ModalFooter>
                            <Button color="primary" onClick={() => console.log('boton')}>Agregar seleccionada</Button>{' '}
                            <Button color="secundary" onClick={toggleImage}>Cancelar</Button>
                        </ModalFooter> */}
            </Modal>
        </>
    )

}
export default AddMarkerForm