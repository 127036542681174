import Dashboard from "views/pages/dashboards/Dashboard.js"
import UserTest from "views/pages/configuration-admin/list-user"
import RegisterUser from "views/pages/configuration-admin/register-user"
//import PatientAdmission from "views/pages/reception/patient-admission"
import ListPatient from "views/pages/reception/list-patient"
import ListRecord from "views/pages/clinical-record/list-records"
import Referral from "views/pages/referral/referral"
import ListPatientAnamnesis from "views/pages/anamnesis/list-patient-anamnesis"
import ConfCapsules from "views/pages/configuration-admin/conf-capsules"
import Unity from "views/pages/configuration-admin/conf-unity"
import ConsultationAppointment from "views/pages/consultation-appointment/consultation-appointment"
import Appointment from "views/pages/appointment/appointment"
import ListPatientAdmission from "views/pages/admission/list-patient-admission";
import DownloadData from "views/pages/configuration-admin/download-data";


const routes = [
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fa fa-columns text-primary",
    miniName: "A",
    component: Dashboard,
    layout: "/admin" 
  },
  {
    path: "/ingreso-paciente-recepcion",
    name: "Pre Ingreso",
    icon: "fa fa-address-book text-primary",
    miniName: "PIR",
    component: ListPatientAdmission,
    layout: "/admin" 
  },
  {
    path: "/agendar-consulta",
    name: "Agendar Consulta",
    icon: "fa fa-address-book text-primary",
    miniName: "AC",
    component: Appointment,
    layout: "/admin" 
  },
  {
    path: "/recepcionar-paciente",
    name: "Recepcionar paciente",
    miniName: "RP",
    icon: "ni fas fa-user-plus text-primary",
    component: ListPatient,
    layout: "/admin"
  },
  {
    path: "/anamnesis-pacientes",
    name: "Crear Anamnesis ",
    miniName: "AP",
    icon: "ni fas fa-user-plus text-primary",
    component: ListPatientAnamnesis,
    layout: "/admin"
  },
  {
    path: "/interconsultas",
    name: "Interconsultas",
    icon: "fa fa-address-book text-primary",
    miniName: "IC",
    component: Referral,
    layout: "/admin" 
  },
  {
    path: "/fichas-clinicas",
    name: "Fichas clinicas",
    miniName: "FC",
    icon: "ni fas fa-user-plus text-primary",
    component: ListRecord,
    layout: "/admin"
    
  },
  {
    collapse: true,
    name: "Mantenedores App",
    icon: "ni fas fa-mobile text-primary",
    state: "MaintainerCollapse",
    layout: "/admin",
    isAdmin: true,
    views: [
      {
        path: "/capsulas-educativas",
        name: "Cápsulas Educativas App",
        miniName: "CE",
        component: ConfCapsules,
        layout: "/admin"
      },
      {
        path: "/Unity",
        name: "Mantenedor de unidades",
        miniName: "MU",
        component: Unity,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Configuración",
    icon: "ni fas fa-cog text-primary",
    state: "ConfigurationCollapse",
    layout: "/admin",
    isAdmin: true,
    views: [
        {
            path: "/registrar-usuario",
            name: "Registrar Usuario",
            miniName: "RU",
            component: RegisterUser,
            layout: "/admin"
        },
        {
            path: "/usuarios",
            name: "Listado de Usuarios",
            miniName: "LU",
            component: UserTest,
            layout: "/admin"
        },
        {
          path: "/Download",
          name: "Descargar Patologías",
          miniName: "DP",
          component: DownloadData,
          layout: "/admin"
        }   
    ]
    }
];

export default routes;