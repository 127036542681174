import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import AuthHelper from "../../../helpers/AuthHelper";
import Agenda from "../components/Agenda/Agenda"
import {Col, Container, Input, Button,Table,  InputGroup, Row, Card, CardBody} from 'reactstrap';
import { getList } from 'helpers/ListHelper';
import moment from 'moment';
import "assets/css/app.css"


const Dashboard = () => {
	const user = AuthHelper.getUserId();

	const [date, setDate] = useState({ start: '', end: '' })

	useEffect(() => {
		handleDate()
	}, [date])




	/*Professionals###########################################################################*/
	const defaultProfessional = { professional_id: 0, professional_fullname: "" };
	const [professional, setProfessional] = useState(defaultProfessional);
	const [professionals, setProfessionals] = useState([]);
	const [displayModalProfessionals, setDisplayModalProfessionals] = useState(false);
	const [searchTermProfessional, setSearchTermProfessional] = useState("");


	const obtener = async professional => {
		setProfessional(professional)
		setDisplayModalProfessionals(!displayModalProfessionals);
	};

	const fetchProfessionals = async () => {
		const res = await getList("user/user-professional");
		setProfessionals(res);
	};

	const defaultAppointment = { cons_appo_id: 0, cons_appo_date: moment().format('YYYY-MM-DD'), cons_appo_start: "09:00", cons_appo_end: "11:00", professional_id: "", cons_appo_place: "", cons_appo_description: "" };

	const [appointment, setAppointment] = useState(defaultAppointment);


	const handleDate = () => {
		setAppointment({
			...appointment,
			cons_appo_date: moment.utc(date.start).format('YYYY-MM-DD'),
			cons_appo_start: moment.utc(date.start, "HH:mm").format('HH:mm'),
			cons_appo_end: moment.utc(date.end, "HH:mm").format('HH:mm'),
		});
	}


	return (
		<>
			{AuthHelper.isAuthenticated() ?
				<Container className="mt-3">
					<Card>
						<CardBody>
					{AuthHelper.getRole() === 5 ?
					
						<Row>
							<Col className="col-8">
								
							{professional.professional_fullname ?  <Col className="px-0 text-center" sm={12}><h2>Dr(a). {professional.professional_fullname}</h2></Col>: <Col className="px-0" sm={12}></Col>}
								
								<Agenda style={{ boxSizing: 'content-box' }} user={professional.professional_id}  date={date} setDate={setDate} handleDate={handleDate}   />
							</Col>
							<Col className="col-4">
							<br />
							<InputGroup>
									<Input
										bsSize="sm"
										type="search"
										placeholder="Buscar profesional..."
										value={searchTermProfessional}
										onChange={e => setSearchTermProfessional(e.target.value)}
									/>

									<Button size="sm" className="fas fa-search btn btn-primary" onClick={() => fetchProfessionals()}>
									</Button>

								</InputGroup>
								<br />
								<div
									style={{
										maxHeight: '16em',
										overflowY: 'auto',
										marginBottom: '1em'
									}}
								>

									<Table className="align-items-center" hover size="sm">

										<tbody>
											{professionals.filter(val => {
												if (searchTermProfessional === "") {
													return val;
												} else if (val.professional_run.includes(searchTermProfessional) || val.professional_fullname.toLowerCase().includes(searchTermProfessional.toLowerCase()) || val.professional_medical_area_name.toLowerCase().includes(searchTermProfessional.toLowerCase())) {
													return val;
												}
											}).map(professional => (
												<tr onClick={() => obtener(professional)} className="text-center text-uppercase" key={professional.professional_id + '' + professional.professional_medical_area_id}>
													<td align="left" className="px-2" style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "250px", textOverflow: "ellipsis" }}>
														{professional.professional_fullname}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
								<hr></hr>
								{/* <Card style={{boxShadow: 'none'}}>
								<CardHeader tag="h4">
									Profesional Seleccionado
								</CardHeader>
								<ListGroup flush>
									<ListGroupItem className="py-2">
									Nombre: 
									</ListGroupItem><h5 style={{fontWeight: '500'}}>{professional.professional_fullname}</h5>
									<ListGroupItem className="py-2">
									Especialidad: 
									</ListGroupItem><h5 style={{fontWeight: '500'}}>{professional.professional_medical_area_name}</h5>
								</ListGroup>
							</Card> */}
							</Col>
						</Row> : <Agenda user={user} dashboard />}</CardBody></Card>
				</Container>
				: <Redirect to="/login" />
			}
		</>
	)
}

export default Dashboard;