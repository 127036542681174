import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Container, FormGroup, Input, Card, CardBody, Label, Table, Modal, ModalHeader, ModalBody, Form, InputGroup, Progress, InputGroupAddon } from 'reactstrap';
import { getList } from 'helpers/ListHelper';
import SimpleModal from "../components/SimpleModal";
import axios from '../../../helpers/axiosConfig';
import classNames from 'classnames';
import useList from '../../../hooks/useList';

const ConfUnity = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    const [formValues, setFormValues] = useState({});
    const [selectedObjetiveId, setSelectedObjetiveId] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [restoreConfirmed, setRestoreConfirmed] = useState(false);
    const [games, setGames] = useState([]);

    const [jsonError, setJsonError] = useState(false);

    const defaultObjetive = { objetive_id: 0, description: "", img: "" };
    const [objetives, setObjetives] = useState([]);
    const [objetive, setObjetive] = useState(defaultObjetive);

    const [displayModalObjetive, setDisplayModalObjetive] = useState(false);
    const [displayModalGame, setDisplayModalGame] = useState(false);

    const [activeTab, setActiveTab] = useState(null);

    const capsules = useList("list/capsules");
    const learning = useList("list/learning");

    const [updatedImageUrl, setUpdatedImageUrl] = useState('');
    const [cacheBuster, setCacheBuster] = useState('');

    const sendGame = async (e) => {
        e.preventDefault();

        const currentGame = games.find(game => game.game_name === activeTab);

        if (currentGame) {
            if (isValidJson(currentGame.json)) {
                setJsonError(false);
                await updateGameData(currentGame);
            } else {
                setJsonError(true);
            }
        }
    };

    //Validar JSON
    function isValidJson(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    const updateGameData = async (game) => {
        try {
            await axios.post("gamification/game/", game);
            fetchGames();
            setDisplayModalGame(!displayModalGame);

        } catch (error) {
            console.error('Error al actualizar los datos del juego:', error);
        }
    };

    const getCurrentGame = () => {
        return games.find(game => game.game_name === activeTab);
    };

    const restoreJson = () => {
        const currentGame = getCurrentGame();
        if (currentGame) {
            setFormValues({
                ...formValues,
                json: currentGame.json_backup
            });

            const gameIndex = games.findIndex(game => game.game_name === activeTab);
            if (gameIndex !== -1) {
                setGames(prevGames => {
                    const newGames = [...prevGames];
                    newGames[gameIndex].json = currentGame.json_backup;
                    return newGames;
                });
            }
        }
    };

    const handleInputChangeGame = (e) => {
        const { name, value } = e.target;
        const gameIndex = games.findIndex(game => game.game_name === activeTab);

        if (gameIndex === -1) return;

        if (name === "atomic" || name === "percentage") {
            if (value < 0 || value > 100) {
                return; // Aborta la actualización si el valor está fuera de rango.
            }
        }

        setFormValues({
            ...formValues,
            [name]: value
        });

        setGames(prevGames => {
            const newGames = [...prevGames];
            const game = newGames[gameIndex];

            if (name === 'learning_objetive_id') {
                game.learning_objetive_id = value;
            } else {

                switch (name) {
                    case 'atomic':
                        game.atomic_points = value;
                        break;
                    case 'percentage':
                        game.percentage_progress = value;
                        break;
                    case 'json':
                        game.json = value;
                        break;
                    default:
                        game[name] = value;
                        break;
                }
            }
            return newGames;
        });
    };

    //FILE###################################################################
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState({});
    const [hasFile, setHasFile] = useState(false);
    const [files, setFiles] = useState([]);
    const extAllowed = "png";
    const sizAllowed = 10000000;
    const defaultHelpText = extAllowed + " (max: " + sizAllowed / 1000000 + " MB)";
    const [helpText, setHelpText] = useState(defaultHelpText);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);

    const handleHasFile = () => {
        setHasFile(!hasFile);
        cleanFile();
        setHelpText(defaultHelpText);
    };

    const loadFile = (file) => {
        if (file[0]?.name) {
            const size = file[0].size;
            const full = file[0]?.name;
            const type = full.slice((full.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();
            if (extAllowed.indexOf(type) >= 0) {
                if (size <= sizAllowed) {
                    setFileName(full.slice(0, (full.lastIndexOf('.'))));
                    setFileType(type);
                    setFile(file);
                    setHelpText(full);
                    return;
                } else {
                    cleanFile();
                    setHelpText("Tamaño de archivo invalido");
                    return;
                };
            };
            cleanFile();
            setHelpText("Formato de archivo invalido");
        };
    };

    const cleanFile = () => {
        setFileName("");
        setFileType("");
        setFile({});
    };

    function processName(name) {
        let processedName = name
            .toLowerCase() // Convertir a minúsculas
            .normalize('NFD') // Descomponer los caracteres acentuados en su forma básica
            .replace(/[\u0300-\u036f]/g, '') // Eliminar los acentos
            .replace(/[^a-z0-9]/g, '_') // Reemplazar todos los caracteres que no sean letras o números con guiones bajos
            .replace(/_+/g, '_'); // Reemplazar múltiples guiones bajos con uno solo

        return processedName;
    }

    const uploadFile = (uploadType, game) => {

        let name = ""
        let endpoint = ""

        if (uploadType === "unit") {
            name = processName(objetive.description);
            endpoint = "file/img_unity/" + name;
        } else if (uploadType === "game") {
            name = processName(game.game_name);
            endpoint = "file/img_game/" + name;
        }

        if (fileName.length > 60) {
            setHelpText("El archivo excede el maximo de caracteres permitidos. (max:60)")
        } else if (fileName) {
            setShowProgress(true);
            const f = new FormData();
            f.append("file", file[0], name + '.' + fileType);
            const config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            }

            axios.post(endpoint, f, config)
                .then(response => {
                    console.log(response)
                    saveFile(uploadType, game);
                    setUploadProgress(0);
                    setShowProgress(false);

                }).catch(error => {
                    console.log(error);
                });
        };
    };

    const saveFile = async (uploadType, game) => {

        let name = "", id = "", endpoint = "", ruta = "";

        if (uploadType === "unit") {
            name = processName(objetive.description);
            id = objetive.objetive_id;
            endpoint = "photo/unity";
            ruta = "static/imagenes/temas/"
        } else if (uploadType === "game") {
            name = processName(game.game_name);
            id = game.game_type_id;
            endpoint = "photo/game";
            ruta = "static/imagenes/juegos/"
        }

        const res = await axios.post(endpoint,
            {
                id: id,
                ruta: ruta + name + "/" + name + '.' + fileType
            }
        );

        console.log(res)

        if (!res.data.error) {
            setUpdatedImageUrl(res.data.img);
            setCacheBuster(Date.now());
            cleanFile();
            setHelpText(defaultHelpText);
            setHasFile(false);
        };
    };

    useEffect(() => {
        fetchObjetives();
    }, []);

    // hola
    useEffect(() => {
        if (games.length > 0 && activeTab == null) {
            setActiveTab(games[0].game_name);
        }
    }, [games]);


    const fetchObjetives = async () => {
        const res_obj = await getList("list/objetive-gamification");
        setLoading(false)
        setObjetives(res_obj);
    };

    const fetchGames = async (objetive_id) => {

        try {
            const res_games = await axios.get("gamification/games-set/" + objetive_id);
            // console.log('los juegos: ', res_games.data.result)

            if (res_games.data && res_games.data.result) {
                setGames(res_games.data.result);
            } else {
                setGames([]);
            }
        } catch (error) {
            console.error('Error al cargar el set de juegos:', error);
        }
    };

    const handleInputChangeObjetive = e => {
        setObjetive({
            ...objetive,
            [e.target.name]: e.target.value
        });
    };

    const showModalObjetive = objetive => {
        setObjetive(objetive);
        setDisplayModalObjetive(!displayModalObjetive);
    };

    const showModalGame = (objetive_id) => {
        fetchGames(objetive_id);
        setSelectedObjetiveId(objetive_id);
        setDisplayModalGame(!displayModalGame);
        setActiveTab(null);
    };

    const modalRef = useRef()

    const setModal = (options) => {
        modalRef.current.setModal(options);
    };

    const toggleTab = game_name => {

        if (activeTab !== game_name) {
            setActiveTab(game_name);
        }
    };

    const sendObjetive = async e => {
        e.preventDefault();

        try {
            await axios.put("gamification/unity/", objetive);
            fetchObjetives();
            setDisplayModalObjetive(!displayModalObjetive);

        } catch (e) {
            console.log('Err: ' + e);
        };
    };

    const updateStateObjetive = async (objetive_id) => {

        await axios.put("gamification/unity/", objetive_id);
        setModal({});
        fetchObjetives();

    };

    const reqTag = <span style={{ color: "#DC3545" }}>*</span>

    return (
        <Container className="mt-3">
            <SimpleModal ref={modalRef} />
            <Card>
                <CardBody>
                    <h1>Mantenedor de unidades App</h1>
                    <Input type="text" placeholder="Buscar..." autoComplete="off" name="look_for" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}></Input>
                    <br />
                    {loading
                        ? <div className="w-100 d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                        : <>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr className="text-center">
                                        <th scope="col">Unidad / Tema</th>
                                        <th scope="col" colSpan="3">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {objetives.filter(e => {
                                        if (searchTerm === "") {
                                            return e;
                                        } else if (e.description.toLowerCase().includes(searchTerm.toLowerCase())) {
                                            return e;
                                        }
                                    }).map(e => (
                                        <tr className="text-center" key={e.objetive_id}>
                                            <td>{e.description}</td>
                                            <td>
                                                <Button onClick={() => showModalObjetive(e)} title="Editar" className="mr-2" color="primary" size="sm" outline><i className="far fa-edit" /></Button>
                                            </td>
                                            <td>
                                                <Button onClick={() => showModalGame(e.objetive_id)} title="Editar-Juego" className="mr-2" color="primary" size="sm" outline><i className="fa fa-gamepad" /></Button>
                                            </td>
                                            {/* <td>
                                                <Button onClick={() => setModal({ title: 'Eliminando unidad', text: '¿Desea desactivar lógicamente la unidad ' + e.description + '?', type: 2, fx: () => updateStateObjetive(e.objetive_id) })} title="Eliminar" className="mr-2" color="danger" size="sm" outline><i className="far fa-trash-alt" /></Button>
                                            </td> */}
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                            {/* <div className="float-right mb-1 mr-4 mt-3">
                                <Button color="primary" onClick={() => showModalObjetive(defaultObjetive)}>Nueva</Button>
                            </div> */}
                        </>
                    }
                </CardBody>
            </Card>
            <Modal isOpen={displayModalObjetive}>
                <ModalHeader toggle={() => { setDisplayModalObjetive(!displayModalObjetive) }}>
                    {objetive.objetive_id === 0 ? 'Nueva unidad' : 'Editando unidad'}
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Form role="form" onSubmit={e => sendObjetive(e)}>
                        <h5 style={{ color: "#DC3545" }}>* Existen campos obligatorios</h5>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Tema {reqTag}</Label>
                                <Input
                                    required autoComplete="off" maxLength="130" type="text"
                                    name="description"
                                    value={objetive.description}
                                    onChange={e => handleInputChangeObjetive(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Imágen de la unidad</Label>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={`${updatedImageUrl || objetive.img}?t=${cacheBuster}`}
                                        alt={`Imagen de ${updatedImageUrl || objetive.img}`}
                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                        id={`game_img_prev_${objetive.img}`}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        {/* {hasFile && */}
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Button
                                        type="button" color="primary"
                                        onClick={() => uploadFile("unit")}>
                                        Agregar
                                    </Button>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    name="treatment_plan"
                                    value={fileName}
                                    onChange={e => setFileName(e.target.value)}
                                    disabled
                                />
                                <InputGroupAddon addonType="append">
                                    <Button
                                        type="button" color="primary"
                                        onClick={() => { document.getElementById('files').click() }}>
                                        Buscar
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <h5 align="right" style={{ marginRight: "10px", marginBottom: "0px" }}>{helpText}</h5>
                            {showProgress && <Progress value={uploadProgress} animated color="success">{uploadProgress}%</Progress>}
                            <input
                                type="file"
                                accept=".png"
                                id="files"
                                style={{ visibility: 'hidden' }}
                                onChange={e => loadFile(e.target.files)}
                            />
                        </FormGroup>
                        {/* } */}
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Cápsula asociada {reqTag}</Label>
                                <Input
                                    bsSize="sm"
                                    type="select"
                                    name="capsule_id"
                                    value={objetive.capsule_id}
                                    required
                                    onChange={e => handleInputChangeObjetive(e)}>
                                    <option hidden value="">Seleccione...</option>
                                    {capsules.map(e => (<option key={e.capsule_id} value={e.capsule_id}>{e.capsule_description}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                        <div className="float-right mb-2 mr-2">
                            <Button type="submit" color="primary">Guardar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal isOpen={displayModalGame} style={{ maxWidth: '1000px' }}>
                <ModalHeader toggle={() => { setActiveTab(null); setDisplayModalGame(!displayModalGame) }}>
                    {games.map(game => (
                        <a
                            key={game.game_name}
                            style={{
                                paddingRight: "15px",
                                cursor: "pointer"
                            }}
                            className={classNames({ 'text-primary': activeTab === game.game_name })}
                            onClick={() => toggleTab(game.game_name)}
                        >
                            {game.game_name}

                        </a>
                    ))}
                </ModalHeader>
                <ModalBody>
                    {games.map(game => {
                        if (game.game_name === activeTab) {
                            return (
                                <div key={game.game_name}>
                                    <Form role="form" onSubmit={e => sendGame(e)}>
                                        <h5 style={{ color: "#DC3545" }}>* Existen campos obligatorios</h5>
                                        <FormGroup className="row">
                                            <Col md="4">
                                                {/* <Label className="form-control-label">Imágen del juego</Label>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img
                                                        src={`${updatedImageUrl || game.game_img}?t=${cacheBuster}`}
                                                        alt={`Imagen de ${updatedImageUrl || game.game_name}`}
                                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                        id={`game_img_prev_${game.game_name}`}
                                                    />
                                                </div> */}
                                                {/* <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <Button
                                                                type="button" color="primary"
                                                                onClick={() => uploadFile("game", game)}>
                                                                Agregar
                                                            </Button>
                                                        </InputGroupAddon>
                                                        <Input
                                                            autoComplete="off"
                                                            type="text"
                                                            name="treatment_plan"
                                                            value={fileName}
                                                            onChange={e => setFileName(e.target.value)}
                                                            disabled
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <Button
                                                                type="button" color="primary"
                                                                onClick={() => { document.getElementById('files').click() }}>
                                                                Buscar
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <h5 align="right" style={{ marginRight: "10px", marginBottom: "0px" }}>{helpText}</h5>
                                                    {showProgress && <Progress value={uploadProgress} animated color="success">{uploadProgress}%</Progress>}
                                                    <input
                                                        type="file"
                                                        accept=".png"
                                                        id="files"
                                                        style={{ visibility: 'hidden' }}
                                                        onChange={e => loadFile(e.target.files)}
                                                    />
                                                </FormGroup> */}
                                                <Label className="form-control-label">Puntaje por item {reqTag}</Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    maxLength="3"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    name="atomic"
                                                    value={game.atomic_points}
                                                    onChange={e => handleInputChangeGame(e)}
                                                />
                                                <Label className="form-control-label">Porcentaje de exigencia {reqTag}</Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    maxLength="3"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    name="percentage"
                                                    value={game.percentage_progress}
                                                    onChange={e => handleInputChangeGame(e)}
                                                />
                                                <Label className="form-control-label">Objetivo de aprendizaje {reqTag}</Label>
                                                <Input
                                                    bsSize="sm"
                                                    type="select"
                                                    name="learning_objetive_id"
                                                    value={game.learning_objetive_id}
                                                    required
                                                    onChange={e => handleInputChangeGame(e)}
                                                >
                                                    <option hidden value="">Seleccione...</option>
                                                    {learning.map(e => (<option key={e.learning_objetive_id} value={e.learning_objetive_id}>{e.description}</option>))}
                                                </Input>
                                            </Col>
                                            <Col md="8">
                                                <Label className="form-control-label">JSON {reqTag}</Label>
                                                <p style={{ color: "#DC3545", textAlign: "justify", fontSize: "0.875rem" }}>
                                                    <b>
                                                        Advertencia: Este campo contiene datos en formato JSON que son fundamentales para
                                                        el funcionamiento correcto de la aplicación. Modificar la estructura de estos datos
                                                        puede provocar errores o comportamientos inesperados.
                                                    </b>
                                                </p>
                                                <textarea
                                                    required
                                                    autoComplete="off"
                                                    name="json"
                                                    value={formValues.json || game.json}
                                                    rows="30"
                                                    style={{ width: "100%" }}
                                                    onChange={e => handleInputChangeGame(e)}
                                                />
                                                {jsonError && <div style={{ color: "#DC3545" }}>Por favor, ingresa un JSON válido.</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" checked={restoreConfirmed} onChange={e => setRestoreConfirmed(e.target.checked)} />{' '}
                                                Confirmo que deseo restaurar el JSON a su valor original. Entiendo que se perderán los cambios que he realizado.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" checked={isConfirmed} onChange={e => setIsConfirmed(e.target.checked)} />
                                                Confirmo que soy conciente de los cambios que estoy realizando.
                                            </Label>
                                        </FormGroup>
                                        <br/>
                                        <div className="d-flex justify-content-between mb-2">
                                            <Button color="warning" onClick={restoreJson} disabled={!restoreConfirmed}><i className="fa fa-hammer" /> Restaurar JSON original</Button>
                                            <Button type="submit" color="primary" disabled={!isConfirmed}>Guardar</Button>
                                        </div>
                                    </Form>
                                </div>
                            );
                        }
                        return null;
                    })}
                </ModalBody>
            </Modal>
        </Container>
    );

};

export default ConfUnity;