import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Label, FormGroup, Input, Button } from 'reactstrap'
import CoronalCavities from './status/coronal-cavitie'
import RadicularCavities from './status/radicular-cavitie'
import Restoration from './status/restoration'
import NonCariousInjury from './status/non-carious-injury'
import ToothModal from './status/tooth-modal'


const ToothStatus = (props, ref) => {
    const { toothSelected, toothStatus, setToothStatus, disabledButton, setDisabledButton } = props

    const checkboxList = [
            { label: 'Sano', id: 'healthy'},
            { label: 'Caries Coronal', id: 'coronal_cavitie'},
            { label: 'Caries Radicular', id: 'radicular_cavitie'},
            { label: 'Restauración', id: 'restoration'},
            { label: 'Lesión no cariosa', id: 'non_carious_injury'},
            { label: 'Tratado endodónticamente', id: 'endodontically_treated'},
            { label: 'Prótesis Fija/ Incrustación/ Carilla', id: 'prosthesis'}
    ]

    const defaultStatus = {
        healthy: {checked: false , disabled : false}, 
        coronal_cavitie: {checked: false , disabled : false}, 
        radicular_cavitie: {checked: false , disabled : false},
        restoration: {checked: false , disabled : false}, 
        non_carious_injury: {checked: false , disabled : false},
        endodontically_treated: {checked: false , disabled : false}, 
        prosthesis: {checked: false , disabled : false}
    }

    const ITEMS_ID = ['coronal_cavitie', 'radicular_cavitie', 'restoration', 'non_carious_injury']

    const [status, setStatus] = useState(defaultStatus)



    const defaultCoronal ={surface:{oclusal:false, vestibular: false, palatino:false, mesial:false, distal:false}, classification:"" , activity:""}
    const defaultRadicular ={surface:{vestibular: false, palatino:false, mesial:false, distal:false}, classification:"" , activity:""}
    const defaultRestoration ={surface: {coronal_oclusal:false, coronal_vestibular: false, coronal_palatino:false, coronal_mesial:false, coronal_distal:false, radicular_vestibular: false, radicular_palatino:false, radicular_mesial:false, radicular_distal:false } , material:"" , status:""}
    const defaultNonCarious ={type:"", surface: {coronal_oclusal:false, coronal_vestibular: false, coronal_palatino:false, coronal_mesial:false, coronal_distal:false, radicular_vestibular: false, radicular_palatino:false, radicular_mesial:false, radicular_distal:false}}

    const [coronal, setCoronal] = useState(defaultCoronal)
    const [radicular, setRadicular] = useState(defaultRadicular)
    const [restoration, setRestoration] = useState(defaultRestoration)
    const [nonCarious, setNonCarious] = useState(defaultNonCarious)

    
    const handleInputCheckbox=(e) =>{
        const {id, disabled, checked} = e.target
        handleChange(e)
        // setStatus({...status, [id]: {checked:checked, disabled: disabled}}) 
    }

    useEffect(() => {
        setStatus(defaultStatus)
        setCoronal(defaultCoronal)
        setRadicular(defaultRadicular)
        setRestoration(defaultRestoration)
        setNonCarious(defaultNonCarious)
        
    }, [toothSelected])
    
    useImperativeHandle(ref, () => ({
        handleStatus
    }));


    // const checkEnable =() =>  status.coronal_cavitie.checked || status.radicular_cavitie.checked || status.restoration.checked || status.non_carious_injury.checked || status.endodontically_treated.checked || status.prosthesis.checked  

    const checkBoxStatus =()=>{
        setStatus({...status,
            healthy: {checked: !!toothStatus.healthy , disabled :false},
            coronal_cavitie: {checked: toothStatus.coronal_cavitie !== null  && JSON.stringify(defaultCoronal) !== toothStatus.coronal_cavitie , disabled : toothStatus.healthy}, 
            radicular_cavitie: {checked: toothStatus.radicular_cavitie !== null && JSON.stringify(defaultRadicular) !== toothStatus.radicular_cavitie , disabled : toothStatus.healthy},
            restoration: {checked: toothStatus.restoration !== null && JSON.stringify(defaultRestoration) !== toothStatus.restoration , disabled : toothStatus.healthy}, 
            non_carious_injury: {checked: toothStatus.non_carious_injury !== null && JSON.stringify(defaultNonCarious) !== toothStatus.non_carious_injury , disabled : toothStatus.healthy},
            endodontically_treated: {checked: !!toothStatus.endodontically_treated, disabled : toothStatus.healthy}, 
            prosthesis: {checked: !!toothStatus.prosthesis , disabled : toothStatus.healthy} 
        })
    }

    const disableNonHealthy = (e) => {
        const { id, checked } = e.target
        checked ?
            setStatus({...status,  
                healthy: {checked:checked, disabled: false}, 
                coronal_cavitie: {checked: false , disabled : true}, 
                radicular_cavitie: {checked: false , disabled : true},
                restoration: {checked: false , disabled : true}, 
                non_carious_injury: {checked: false , disabled : true},
                endodontically_treated: {checked: false, disabled : true}, 
                prosthesis: {checked: false , disabled : true}
            })
            :
            setStatus({...status,  
                healthy: {checked:checked, disabled: false}, 
                coronal_cavitie: {checked: false , disabled : false}, 
                radicular_cavitie: {checked: false , disabled : false},
                restoration: {checked: false , disabled : false}, 
                non_carious_injury: {checked: false , disabled : false},
                endodontically_treated: {checked: false, disabled : false}, 
                prosthesis: {checked: false , disabled : false}
            })
    }


    const disableHealthy = (e) => {
        const { id, checked, disabled } = e.target
        !checked 
        & Object.values(status).filter(item => item.id !== id & item.checked === true).length === 1
        ?
        setStatus({...status, healthy :{checked: false, disabled: false}, [id]: {checked:checked, disabled: false} })
        : 
        setStatus({...status, healthy :{checked: false, disabled: true}, [id]: {checked:checked, disabled: false} })
    }


    const handleChange = (e) => {
        e.target.id === 'healthy' ? disableNonHealthy(e) : disableHealthy(e)
    }

    useEffect(() => {
        checkBoxStatus()
    }, [toothStatus])

    const handleStatus =()=>{
        setToothStatus(
            {  ...toothStatus,
                fdi_name: toothSelected,
                healthy: status.healthy.checked,
                coronal_cavitie: JSON.stringify(coronal),
                radicular_cavitie: JSON.stringify(radicular),
                restoration: JSON.stringify(restoration),
                non_carious_injury: JSON.stringify(nonCarious),
                endodontically_treated: status.endodontically_treated.checked,
                prosthesis: status.prosthesis.checked
            }
        )
    }

    useEffect(() => {
        
        setCoronal(status.coronal_cavitie.checked & toothStatus.coronal_cavitie !== '' & toothStatus.coronal_cavitie !== null ? JSON.parse(toothStatus?.coronal_cavitie): defaultCoronal )
    }, [status.coronal_cavitie.checked])
    
    useEffect(() => {
        
        setRadicular(status.radicular_cavitie.checked & toothStatus.radicular_cavitie !== '' & toothStatus.radicular_cavitie !== null ? JSON.parse(toothStatus?.radicular_cavitie): defaultRadicular )
    }, [status.radicular_cavitie.checked])
    
    useEffect(() => {
        
        setRestoration(status.restoration.checked & toothStatus.restoration !== '' & toothStatus.restoration !== null? JSON.parse(toothStatus?.restoration) : defaultRestoration )
    }, [status.restoration.checked])

    useEffect(() => {
        
        setNonCarious(status.non_carious_injury.checked & toothStatus.non_carious_injury !== '' & toothStatus.non_carious_injury !== null ? JSON.parse(toothStatus?.non_carious_injury): defaultNonCarious )
    }, [status.non_carious_injury.checked])

    
    const [coronalOpen, setCoronalOpen] = useState(false)
    const toggleCoronal =()=> setCoronalOpen(!coronalOpen)
    const [radicularOpen, setRadicularlOpen] = useState(false)
    const toggleRadicular =()=> setRadicularlOpen(!radicularOpen)
    const [restorationOpen, setRestorationOpen] = useState(false)
    const toggleRestoration =()=> setRestorationOpen(!restorationOpen)
    const [nonCariousOpen, setNonCariousOpen] = useState(false)
    const toggleNonCarious =()=> setNonCariousOpen(!nonCariousOpen)


    const handleModalOpen =(id)=>{
        id === 'coronal_cavitie' && toggleCoronal()
        id === 'radicular_cavitie' && toggleRadicular()
        id === 'restoration' && toggleRestoration()
        id === 'non_carious_injury' && toggleNonCarious()
    }

    return (
        <>
            <Label className="form-control-label">Diente {toothSelected}</Label>
            {checkboxList.map((item, index) => (
                <FormGroup className="my-2" check key={index}>
                    <div className="custom-control custom-checkbox custom-checkbox-primary ">
                        <Input
                            className="custom-control-input align-middle"
                            id={item.id}
                            type="checkbox"
                            checked={status[item.id].checked}
                            disabled={status[item.id].disabled}
                            onChange={(e) => handleInputCheckbox(e)}
                        />
                        <label
                            className="custom-control-label align-middle"
                            htmlFor={item.id}
                        >{item.label}</label>{' '}
                        {(status[item.id].checked && item.id === ITEMS_ID.find(element => item.id ===element))
                            &&
                            <Button className="ml-3 px-1 py-0 align-middle" onClick={() => handleModalOpen(item.id)} color="primary" size="sm">
                                <i className="fa fa-pencil-alt"></i>
                            </Button>
                        }
                    </div>
                </FormGroup>
            ))}
                {checkboxList.map((item, index) => (
                <Fragment key={index}>
                        {item.id === 'coronal_cavitie' &&
                            <ToothModal
                            isOpen={coronalOpen}
                            name={item.label}
                            toggle={toggleCoronal}
                            // action={actionModal}
                            >
                                <CoronalCavities data={coronal} setData={setCoronal} /> 
                            
                        </ToothModal>
                        }
                        {item.id === 'radicular_cavitie' &&
                        <ToothModal
                            isOpen={radicularOpen}
                            name={item.label}
                            toggle={toggleRadicular}
                            // action={actionModal}
                        >
                            <RadicularCavities data={radicular} setData={setRadicular}/>
                        </ToothModal>
                        }
                        {item.id === 'restoration' &&
                        <ToothModal
                            isOpen={restorationOpen}
                            name={item.label}
                            toggle={toggleRestoration}
                        //  action={actionModal}
                        >
                            <Restoration data={restoration} setData={setRestoration} />                         
                        </ToothModal>
                        }
                        {item.id === 'non_carious_injury' &&
                        <ToothModal
                            isOpen={nonCariousOpen}
                            name={item.label}
                            toggle={toggleNonCarious}
                        //  action={actionModal}
                        >
                            <NonCariousInjury data={nonCarious} setData={setNonCarious} />
                        
                        </ToothModal>
                        }




                    
                </Fragment>
                ))}

            {/* {data.map((item, index) => (
                <Fragment key={index}>
                    {(item.checked && ITEMS_ID.find(element => element === item.id))
                        &&
                        <ToothModal
                            open={item.checked}
                            name={item.label}
                            action={openEditModal}
                        >
                            {item.id === 'coronal-cavitie' ?
                                <CoronalCavities data={coronal} setData={setCoronal} /> :
                                item.id === 'radicular-cavitie'
                                    ? <RadicularCavities data={radicular} setData={setRadicular} /> :
                                    item.id === 'restoration' ? <Restoration data={restoration} setData={setRestoration} /> :
                                        item.id === 'non-carious-injury' && <NonCariousInjury data={nonCarious} setData={setNonCarious} />
                            }
                        </ToothModal>
                    }
                </Fragment>
            ))} */}

            {/* <Button className="ml-3 px-1 py-0 align-middle" color="primary" ></Button> */}

            {/* <Button color="primary" onClick={() => handleStatus()}>Guardar</Button>{' '} */}
        </>
    )
}
export default forwardRef(ToothStatus)



    // const actionModal = (e) => {
    //     setCoronal(e)
    // }



   // const checkboxListDefault = [
    //     { label: 'Sano', id: 'healthy', disabled: false, checked: false },
    //     { label: 'Caries Coronal', id: 'coronal-cavitie', disabled: false, checked: false },
    //     { label: 'Caries Radicular', id: 'radicular-cavitie', disabled: false, checked: false },
    //     { label: 'Restauración', id: 'restoration', disabled: false, checked: false },
    //     { label: 'Lesión no cariosa', id: 'non-carious-injury', disabled: false, checked: false },
    //     { label: 'Tratado endodónticamente', id: 'endodontically-treated', disabled: false, checked: false },
    //     { label: 'Prótesis Fija/ Incrustación/ Carilla', id: 'prosthesis', disabled: false, checked: false }
    // ]

    // const ITEMS_ID = ['coronal-cavitie', 'radicular-cavitie', 'restoration', 'non-carious-injury']






   // <ToothModal
                        //     isOpen={status[item.id].checked}
                        //     name={item.label}
                        //     // toggle={toggle}
                        //     // action={actionModal}
                        // >
                        //     {item.id === 'coronal_cavitie' ?
                        //         <CoronalCavities data={coronal} setData={setCoronal} /> :
                        //         item.id === 'radicular_cavitie'
                        //             ? <RadicularCavities data={radicular} setData={setRadicular} /> :
                        //             item.id === 'restoration' ? <Restoration data={restoration} setData={setRestoration} /> :
                        //                 item.id === 'non_carious_injury' && <NonCariousInjury data={nonCarious} setData={setNonCarious} />
                        //     }
                        // </ToothModal>













    // const [data, setData] = useState(checkboxListDefault)


    // const [coronal, setCoronal] = useState()
    // const [radicular, setRadicular] = useState()
    // const [restoration, setRestoration] = useState()
    // const [nonCarious, setNonCarious] = useState()

    // const [openModal, setOpenModal] = useState(false)


    // const handleChange = (e) => {
    //     e.target.id === 'healthy' ? disableNonHealthy(e) : disableHealthy(e)
    // }

    // const disableNonHealthy = (e) => {
    //     const { checked } = e.target
    //     console.log(checked)
    //     setData(data.map((item) => (item.id !== 'healthy' ? { ...item, checked: false, disabled: checked } : { ...item, checked: checked, disabled: false })))
    // }

    // const disableHealthy = (e) => {
    //     const { id, checked, disabled } = e.target
    //     !checked & data.filter(item => item.id !== id & item.checked === true).length === 0 ?
    //         setData(data.map((item) => ({ ...item, checked: false, disabled: false }))) :
    //         setData(data.map((item) => (item.id === 'healthy' ? { ...item, checked: false, disabled: true } : item.id === id ? { ...item, checked: checked, disabled: disabled } : { ...item })))
    // }

    // const handleEdit = (id) => {
    //     setOpenModal(true)
    //     console.log(id)
    //     //setData(data.map((item) => ( item.id === id  ? {...item, checked: true }:{...item})))
    // }

    // const openEditModal = (e) => {
    //     console.log(e)
    // }

    // // const actionModal = (e) => {
    // //     !e &&
    // //         setData(data.map((item) => ({ ...item, checked: false })))
    // //     //console.log(e)
    // // }

    // const handleStatus = () => {
    //     setData (data.map((item) => (
    //         item.id ==="healthy" ? { ...item, checked:  toothStatus?.healthy === 1 || toothStatus?.healthy===true ? true: false } : 
    //         item.id==="coronal-cavitie" ? { ...item, checked:  toothStatus?.coronal_cavitie  !== '' || toothStatus?.coronal_cavitie!== null }: 
    //         item.id==="radicular-cavitie" ? { ...item, checked: toothStatus?.radicular_cavitie !== '' || toothStatus?.radicular_cavitie !== null }:
    //         item.id==="restoration" ? {...item, checked: toothStatus?.restoration !== null }:
    //         item.id==="non-carious-injury"  ?{...item, checked: toothStatus?.non_carious_injury !== null} :
    //         item.id==="endodontically-treated" ? {...item, checked: toothStatus?.endodontically_treated === 1  } :
    //         item.id === "prosthesis" ? { ...item, checked: toothStatus?.prosthesis === 1 }:{...item}
    //         )))
    //     setToothStatus({
    //         ...toothStatus,
    //         fdi_name: toothSelected,
    //         healthy: data[0]?.checked,
    //         coronal_cavitie: data[1]?.checked ? coronal: null,
    //         radicular_cavitie: data[2]?.checked ? radicular: null,
    //         restoration: data[3]?.checked ? JSON.stringify(restoration) : null,
    //         non_carious_injury: data[4]?.checked ? JSON.stringify(nonCarious) : null,
    //         endodontically_treated: data[5].checked,
    //         prosthesis: data[6].checked
    //     })
    // }

    // useImperativeHandle(ref, () => ({
    //     handleStatus
    // }));

    // useEffect(() => {
    //     data.some(item => item.checked) ? setDisabledButton(false) : setDisabledButton(true)
    // }, [data])



    // useEffect(() => {
    //     setData (data.map((item) => (
    //         item.id ==="healthy" ? { ...item, checked:  toothStatus?.healthy === 1 || toothStatus?.healthy===true ? true: false } : 
    //         item.id==="coronal-cavitie" ? { ...item, checked:  toothStatus?.coronal_cavitie!== null }: 
    //         item.id==="radicular-cavitie" ? { ...item, checked:  toothStatus?.radicular_cavitie !== null }:
    //         item.id==="restoration" ? {...item, checked: toothStatus?.restoration !== null }:
    //         item.id==="non-carious-injury"  ?{...item, checked: toothStatus?.non_carious_injury !== null} :
    //         item.id==="endodontically-treated" ? {...item, checked: toothStatus?.endodontically_treated === 1 || toothStatus?.endodontically_treated===true ? true: false } :
    //         item.id === "prosthesis" ? { ...item, checked: toothStatus?.prosthesis === 1 || toothStatus?.prosthesis===true ? true: false }:{...item}
    //         )))
        
    // }, [toothStatus, toothSelected])
    
    // // console.log(toothStatus)

    // useEffect(() => {
    //     toothStatus&&
    //     setCoronal(toothStatus.coronal_cavitie)
    // }, [toothStatus])
